'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var repeat = exports.repeat = function repeat(str, times) {
    return new Array(times + 1).join(str);
};

var padStart = exports.padStart = function padStart(num, maxLength) {
    var char = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : ' ';
    return repeat(char, maxLength - num.toString().length) + num;
};

var formatTime = exports.formatTime = function formatTime(time) {
    var h = padStart(time.getHours(), 2, '0');
    var m = padStart(time.getMinutes(), 2, '0');
    var s = padStart(time.getSeconds(), 2, '0');
    var ms = padStart(time.getMilliseconds(), 3, '0');
    return h + ':' + m + ':' + s + '.' + ms;
};

var now = exports.now = function now() {
    return formatTime(new Date());
};