'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.enableLogging = undefined;

var _mobx = require('mobx');

var _log = require('./log');

var _log2 = _interopRequireDefault(_log);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var defaultOptions = {
    action: true,
    reaction: true,
    transaction: true,
    compute: true,
    predicate: function predicate() {
        return true;
    }
};

var enableLogging = exports.enableLogging = function enableLogging() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultOptions;

    var predicate = options.predicate || defaultOptions.predicate;
    if (predicate() === true) {
        return (0, _mobx.spy)(function (ev) {
            return (0, _log2.default)(ev, options);
        });
    }
    return function () {
        return void 0;
    };
};

exports.default = enableLogging;