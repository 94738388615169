"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = rtrim;

var _assertString = _interopRequireDefault(require("./util/assertString"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function rtrim(str, chars) {
  (0, _assertString.default)(str);
  var pattern = chars ? new RegExp("[".concat(chars, "]")) : /\s/;
  var idx = str.length - 1;

  for (; idx >= 0 && pattern.test(str[idx]); idx--) {
    ;
  }

  return idx < str.length ? str.substr(0, idx + 1) : str;
}

module.exports = exports.default;